<template>
  <div class="alert" :class="background">
    <div class="alert-header">

      <span class="btn cursor-default text-muted px-3 text-uppercase strong"
            :class="color"
            v-if="!to && title">
        <i class="mr-2" :class="_icon" v-if="_icon"></i>
        {{title}}
      </span>

      <router-link class="btn text-muted px-3 text-uppercase strong"
                   :class="color"
                   :to="to"
                   v-if="to && title">
        <i class="mr-2" :class="_icon" v-if="_icon"></i>
        {{title}}
      </router-link>

      <span class="btn text-muted p-0 ml-auto"
            :class="color"
            @click="close">
        <i class="i-close"></i>
      </span>
    </div>
    <div class="alert-body pt-0">
      <slot></slot>
    </div>
  </div>
</template>

<script>
  export default {
    props: {
      title: [String, Number],
      to: [String, Number],
      icon: String,
      type: String
    },
    data() {
      return {

      }
    },
    computed: {
      background() {
        return this.type ? 'alert-'+this.type : '';
      },
      color() {
        return this.type ? 'text-'+this.type : '';
      },
      _icon() {
        let icon = '';

        if(this.type === 'success') icon = 'i-check';
        if(this.type === 'danger') icon = 'i-close';
        if(this.type === 'warning') icon = 'i-info';
        if(this.type === 'info') icon = 'i-help-circle';

        if(this.icon) icon = this.icon;

        return icon;
      }
    },
    methods: {
      close() {
        this.$emit('close');
        this.$el.outerHTML = '';
      }
    }
  }
</script>
